import UserpicLink from "./UserpicLink"
import UsernameLink from "./UsernameLink"
import Dateline from "./Dateline"

function Byline({ createdAt, creator, isLinked, by }) {
  return(
    <div className="byline">
      <div className="name-and-date">
        <div className="author-name">
          { creator && <UsernameLink user={creator} withBy={true} by={by} isLinked={isLinked} /> }
        </div>
        <div className="dateline">
          { createdAt && <Dateline timestamp={createdAt} isLinked={isLinked} /> }
        </div>
      </div>
      {creator && <UserpicLink user={creator} isLinked={isLinked} />}
    </div>
  )
}

export default Byline
